// http://ionicframework.com/docs/theming/
@import "~@ionic/core/css/core.css";
@import "~@ionic/core/css/normalize.css";
@import "~@ionic/core/css/structure.css";
@import "~@ionic/core/css/typography.css";

@import "~@ionic/core/css/padding.css";
@import "~@ionic/core/css/float-elements.css";
@import "~@ionic/core/css/text-alignment.css";
@import "~@ionic/core/css/text-transformation.css";
@import "~@ionic/core/css/flex-utils.css";
@import "~animate.css/animate.min.css";
@import "./theme/variables.scss";

@font-face {
  font-family: SF Pro;
  src: url("./assets/fonts/SFProDisplay-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: SF Pro;
  src: url("./assets/fonts/SFProDisplay-Semibold.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: SF Pro;
  src: url("./assets/fonts/SFProDisplay-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: SF Pro;
  src: url("./assets/fonts/SFProDisplay-Thin.ttf");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: SF Pro;
  src: url("./assets/fonts/SFProDisplay-Heavy.ttf");
  font-weight: 800;
  font-style: normal;
}

.plt-desktop {
  --page-max-width: 520px;
  --page-box-shadow: 0px -40px 40px 0px #00000052;
}

.plt-desktop body {
  // We're limiting the width of the app to avoid having it stretch on
  // wide screens, given we're optimizing for mobile only. This is a
  // temporary background to avoid having the app look empty on wide screens.
  // TODO: Replace this once we have a proper background for the app
  background: linear-gradient(168.65deg, #275ab9 0%, #33afd5 48.99%, #e3e6c4 99.02%);
}

.plt-desktop ion-app {
  background-color: #fff;
  box-shadow: var(--page-box-shadow);
  margin: 0 auto;
  max-width: var(--page-max-width);
  width: 100%;
}

.cbhAppV1,
ion-popover,
ion-modal {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  ion-button {
    font-weight: 600;
  }

  .md.button {
    text-transform: none;
  }

  .footer-md:before {
    background-image: none;
  }

  .displayNone {
    display: none !important;
  }

  ion-input {
    --background: var(--ion-color-light);
    --placeholder-color: var(--ion-color-medium);
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 5px;
    font-size: 14px;
    height: 50px;
  }

  .button.button-block.button-large {
    height: 50px;
  }

  .title-ios {
    -webkit-padding-start: 20px;
    padding-inline-start: 20px;
    -webkit-padding-end: 20px;
    padding-inline-end: 20px;
  }

  .new-flow .title,
  .instructions > h1,
  .instructions > h2,
  .instructions > h3,
  .instructions > h4,
  .instructions > h5,
  .instructions > h6 {
    font-size: 16px;
    font-weight: 700;
    color: #333;
  }

  .new-flow .instructions > h3 {
    font-size: 16px;
  }

  .new-flow .no-text-transform {
    text-transform: none;
  }

  .new-flow .no-padding-left {
    padding-left: 0 !important;
  }

  .new-flow .info-icon {
    font-size: "20px";
    color: "#106fbf";
    padding-bottom: "14px";
  }

  .upload-preview {
    border-radius: 0.5rem;
    padding: 5px;
    margin: 1rem;
    background: #f5f5f5;
    position: absolute;
    text-align: center;
    max-width: 90%;
    display: block;
    margin: 0 auto;
    top: 15%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 310px;
    .icon {
      position: absolute;
      right: -1rem;
      font-size: 2rem;
      top: -1rem;
    }
  }
  @media (max-width: 320px) {
    .upload-preview {
      height: 250px;
    }
  }

  .uploaded-timesheet {
    position: absolute;
    z-index: 9999;
    right: 0;
    margin: 0 auto;
    display: block;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 5px;
    border-radius: 8px;
    width: auto;
    height: inherit;
  }

  .new-flow.shifts.ongoing {
    flex: 1 !important;
    .shift-timeline {
      margin-left: 0 !important;
      padding-left: 1px !important;
    }
    .check-instructions-ongoing {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    p,
    ol > li {
      color: #555555;
      margin-bottom: 0.2rem;
      font-size: 14px;
    }
    ol {
      padding-left: 25px;
    }
    .instructions {
      font-size: 12px;
    }
    ion-note {
      color: #828282 !important;
      font-size: 12px !important;
      display: flex !important;
      align-items: center;
      ion-icon {
        font-size: 18px;
      }
    }
  }

  .ion-page.overlay::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 50%);
    z-index: 200;
    top: 0;
    left: 0;
  }

  ion-title {
    font-weight: 400;
  }

  ion-label p,
  ion-item p {
    font-size: 12px;
  }

  ion-chip.condensed {
    margin-left: 0;
    font-size: 0.75rem;
    font-weight: 400;
    height: 26px;
    padding: 0 20px;
    line-height: 1rem;
  }

  ion-chip.small-chip {
    height: auto;
    -webkit-padding-start: 10px;
    padding-inline-start: 10px;
    -webkit-padding-end: 10px;
    padding-inline-end: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    ion-label {
      font-size: 10px;
    }
  }

  ion-chip.small-chip[outline="true"] {
    background: rgba(var(--ion-color-base-rgb), 0.08);
  }

  .has-select ion-select {
    max-width: 90%;
    width: 90%;
  }

  ion-select {
    font-size: 14px;
  }

  ion-input {
    background: transparent;
  }
  .border-enable {
    input {
      border: 1px solid #b7b5b5;
      border-radius: inherit;
      margin: 0px;
      padding: 3px;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .button-auto-height {
    height: auto;
    white-space: normal;
    --padding-top: 7px;
    --padding-bottom: 7px;
    --padding-start: 7px;
    --padding-end: 7px;
  }

  .footer-overlay {
    height: 40px;
    opacity: 0.7;
    background: #ffffff;
    position: absolute;
    top: -40px;
    width: 100%;
  }

  .flex-center-align {
    display: flex;
    justify-content: center;
  }

  .timesheet-button-row {
    display: flex;
    flex-flow: column;
    justify-content: center;
    .upload-button {
      border-radius: 10px;
      text-decoration: none;
    }
  }

  .flex-dir-col {
    flex-direction: column;
  }

  .middle-align-in-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .uppercase {
    text-transform: uppercase;
  }

  .fit-content {
    width: fit-content;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .file-upload-container {
    border-radius: 10px;
    background: #dedede;
    vertical-align: -webkit-baseline-middle;
    color: var(--ion-color-tertiary);
    padding: 30px;
    font-size: 20px;
    margin-bottom: 0;

    &.btn {
      padding: 10px !important;
    }
  }

  .file-upload-container > hr {
    border-top: 1px solid #545458;
    opacity: 0.5;
  }
  .file-upload-cancel-btn {
    margin-bottom: 5px auto 0 auto;
    border-radius: 10px;
    background: #fff;
    vertical-align: -webkit-baseline-middle;
    color: var(--ion-color-tertiary);
    padding: 12px;
    font-size: 20px;
    font-weight: 500;
  }
  .facility-info-icon {
    left: 15px;
  }
  .facility-info-icon-unverified {
    width: auto;
    flex: none;
    .icon {
      font-size: 2em;
      margin-right: 5px;
    }
  }
  .facility-info-link {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    color: #1c69d1 !important;
    cursor: pointer;
  }

  .unverified-shifts-danger-icon {
    left: 10px;
    top: 10px;
  }
  .unverified-shifts-count {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #828282;
    cursor: pointer;
  }
  .unverified-shifts-timesheet-count {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
    cursor: pointer;
  }
  .no-timesheet-select {
    justify-content: center;
    display: flex;
    text-decoration: underline;
  }
  .payment-text-color {
    color: #1c69d1;
  }
  .payment-text-color-instantpay {
    color: #219653;
  }

  .shift-title-color {
    color: #1c69d1;
  }

  .primary-background-color {
    background-color: #1c69d1;
  }

  .dark-background-color {
    background-color: #828282;
  }

  .shift-title {
    font-size: 20px;
  }

  .payment-text-color-instantpay {
    color: #219653;
  }
  .timesheet-upload {
    zindex: 300;
    position: fixed;
    bottom: 1rem;
    left: 0;
    right: 0;
    top: 5rem;
    display: flex;
    flexdirection: column;
  }

  .instant-pay-icon {
    font-size: 20px;
    position: relative;
    top: 4px;
    margin-left: 2px;
  }

  .shift-details-card {
    box-shadow: none;
  }

  .upload-time-overlay {
    z-index: 300;
    position: fixed;
    bottom: 1rem;
    left: 0;
    right: 0;
    top: 5rem;
    display: flex;
    flex-direction: column;
  }

  .upload-time-group {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .shift-timeline-new {
    display: flex;
    justify-content: space-between;
  }

  .shift-timeline-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    h2 {
      font-size: 22px;
      font-weight: 600;
    }
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .ellipsis {
    padding: 5px 10px 0 0;
    display: block;
    display: -webkit-box;
    font-size: 12px;
    white-space: pre-line;
    color: #9e9e9e;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .missing-docs-color {
    color: #fdc577;
  }
  .cancel-button {
    flex: 1;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 400;
    max-height: 60px;
  }
  .shift-cancel-button {
    font-size: 14px;
    font-weight: 400;
    max-height: 60px;
    text-decoration: none;
  }
  .facility-list-item {
    &:nth-of-type(odd) {
      background-color: white;
    }
  }
  .canceling {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    max-height: 60px;
  }

  .otp-field-border {
    border-bottom: none;
    input {
      padding: 6px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 4px;
      text-align: left;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &.no-border input {
      // increase specificity and override the above set border
      border: none;
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
  }
}

ion-popover .popover-content {
  /**
   * See: https://clipboardhealth.atlassian.net/browse/CH-21369
   * On Chrome, web version: Timesheet popover doesn’t work correctly.
   * We also observe this on devices.
   * https://app.uxcam.com/share/sessions/LgJYpkSZChYxQYnT5rcqBJ1jVmdUf2muuO63jzIr8J6HHczveEIUZTB
   * hacky way to show popovers in latest chrome browsers
   * FIXME: Remove this when we update ionic/core.
   */
  [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
  }
}

.document-progress-title {
  padding-top: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  .total {
    margin-right: 0.5em;
  }
}

.resend-otp-link {
  text-decoration-line: underline;
  color: #828282;
  &:hover,
  &:active,
  &:focus {
    color: #828282;
  }
}

ion-radio-group.invalid ion-item ion-label {
  color: hsl(359deg 68% 59%) !important;
}

ion-textarea.invalid {
  color: red;
  background-color: hsl(359deg 68% 95%) !important;
}

.learn-more-link {
  text-decoration-line: underline;
  --color: #000;
}

.hr {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
}

.onboarding-page {
  --background: transparent;

  .onboarding-header {
    background: transparent;

    &::after {
      content: none;
    }

    .onboarding-toolbar {
      --background: transparent;
      padding: 0 1rem;
      .top-btns {
        height: auto;
        font-size: 26px;
        margin: 0;
        --padding: 0;
        --padding-start: 0;
        --padding-end: 0;
      }

      ion-back-button {
        color: inherit;
      }
    }
  }

  .content-layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    .agreement-header {
      padding: 0 1rem;
      .heading h4 {
        text-align: left;
        color: #333333;
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 800;
      }
      p {
        text-align: justify;
      }
    }

    .form-container {
      flex: 1;
      .form-heading {
        color: #333333;
        margin-bottom: 24px;
        h4 {
          font-size: 24px;
          font-weight: 800;
        }
        p {
          font-size: 15px;
          font-weight: 300;
        }
      }
      .date-time-picker {
        padding-left: 0;
      }

      .date-time-picker-dob {
        padding-left: 0;
        font-weight: 600;
      }

      .form-label {
        text-transform: uppercase;
        font-size: 14px;
        color: #4f4f4f;
        font-weight: 500;

        &.hcp-self-label {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }
      }
      .form-input {
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        border-bottom: 1px solid #e0e0e0;
        border-radius: 0;
        --padding-start: 0;
        --padding-bottom: 2;
        --placeholder-color: #bdbdbd;

        &.hcp-self-input {
          font-family: Roboto;
          font-weight: 700;
          line-height: 19px;
          border-bottom: none;
        }
      }
      .form-item-wrapper {
        --inner-padding-start: 0;
        --padding-start: 0;
      }
      .form-icon[slot="start"] {
        margin-inline-end: 20px;
      }
      &.adjust-form-height {
        // Helps in lifting the button below the viewing height;
        max-height: calc(100% - 70px);

        & .form-label {
          font-size: 16px;
        }
      }
    }

    .footer-container {
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }

  .continue-button {
    --box-shadow: none;
    --border-radius: 50px;
    --background: #1c69d1;
    width: 100%;
    transition: all 0.3s ease-in;
    font-size: 16px;
    &[disabled=""] {
      --background: #cccccc;
    }
    &.slim {
      height: 34px;
    }
  }
}

.verifyotp-content {
  .resend-otp-button {
    margin-top: 50px;
    text-decoration: underline;
    --color: #828282;
    --padding-start: 0;
    --padding-end: 0;
  }

  ion-input {
    border-bottom: var(--ion-color-medium) 1px solid;
    text-align: center;
  }

  .resend-button {
    font-size: 10px;
    text-transform: none;
  }
}

@media (max-width: 360px) {
  .onboarding-page {
    ion-button.continue-button {
      height: 40px !important;
    }
  }
}

.ion-login-alert-popup {
  --max-width: 320px;
  .alert-wrapper {
    padding-top: 10px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    padding: 10px;
    .alert-head {
      .alert-title {
        font-family: SF Pro Display;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
      }
    }
    .alert-message {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #333333;
      a {
        text-decoration: underline;
      }
    }
    .alert-button-group {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .alert-button {
        border: 2px solid #5da0fb;
        border-radius: 32px;
        text-transform: none;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        margin: 0;
        .alert-button-inner {
          justify-content: center;
        }
      }
      .ion-login-alert-ok-button {
        border: 2px solid #828282;
        color: #828282;
      }
    }
    .alert-head {
      padding-bottom: 10px;
      padding-top: 10px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 29px;
    }
  }
}
.distance-note {
  margin-right: 5px;
}

// ion-color-gray appears to be unused.
.ion-color-gray {
  --ion-color-base: var(--ion-color-gray);
  --ion-color-base-rgb: var(--ion-color-gray-rgb);
  --ion-color-contrast: var(--ion-color-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-gray-shade);
  --ion-color-tint: var(--ion-color-gray-tint);
}

.signature-pad-canvas {
  display: flex;
  align-content: center;
  justify-content: center;
  border: 1px solid grey;
  width: 98%;
  flex-grow: 1;
  /*
    flex items by default can't be smaller than their content.
    Because of this behavior, when our signature pad renders initially
    it is larger than our outer wrapper and flex can't size it down.
    Setting this min-height enables it to be resized down initially so
    that it fits our outer wrapper.
  */
  min-height: 104px; // divisible by 8, to match design system
  /*
    This property sets the initial size of the element before
    any flex styles are applied. It is set here to prevent an
    issue on iOS 14 where this container is larger than its parent
    and doesn't scale down to fit.
  */
  flex-basis: 50%;
}

.sign-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px -5px;
  padding-top: 15px;
  width: 100%;
  overflow-x: auto;
}

.sign-button {
  flex-grow: 1;
  /*
    This is not divisible by 8, because it matches the global style for
    smaller buttons which is set to 30px. Changing it cause misalignment
    with other button elements.
    todo - fix when we consolidate button sizes across app.
  */
  height: 30px;
  margin: 4px;
}

// Hide Zendesk launcher button
/**
 * See https://github.com/ClipboardHealth/cbh-mobile-app/pull/172/files#r921512223
 * This is an attempt to hide a third party widget.
 * This doesn't work on web, but maybe it does on mobile?
 * See also https://linear.app/clipboardhealth/issue/FEF-492/hide-zendesk-button-on-web
 * This could just be: `window.zE("messenger:set", "zIndex", -1);`
 * FIXME: Should we delete this
 */
[title="Button to launch messaging window"] {
  z-index: -1 !important;
}

.button-reset {
  all: unset;
  cursor: pointer;
}
