// Ionic Variables and Theming. For more info, please see:
// https://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #106fbf;
  --ion-color-primary-rgb: 88, 135, 249;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #4d77db;
  --ion-color-primary-tint: #6993fa;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #2ecc71;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  --ion-color-warning-checkr: #ff6b00;

  .ion-color-checkr-warning {
    --ion-color-warning-checkr: var(--ion-color-warning-checkr);
  }

  /** danger **/
  --ion-color-danger: #d93025;
  --ion-color-danger-rgb: 217, 48, 37;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #bf2a21;
  --ion-color-danger-tint: #dd453b;

  /** dark **/
  --ion-color-dark: #001529;
  --ion-color-dark-rgb: 0, 21, 41;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #001224;
  --ion-color-dark-tint: #1a2c3e;

  /** medium **/
  --ion-color-medium: #bcc5d3;
  --ion-color-medium-rgb: 195, 203, 215;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #a5adba;
  --ion-color-medium-tint: #c3cbd7;

  /** light **/
  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #e0e0e0;
  --ion-color-light-tint: #ffffff;

  /** gold **/
  --ion-color-gold: #faad14;
  --ion-color-gold-rgb: 250, 173, 20;
  --ion-color-gold-contrast: #ffffff;
  --ion-color-gold-contrast-rgb: 255, 255, 255;
  --ion-color-gold-shade: #dc9812;
  --ion-color-gold-tint: #fbb52c;

  .ion-color-gold {
    --ion-color-base: var(--ion-color-gold);
    --ion-color-base-rgb: var(--ion-color-gold-rgb);
    --ion-color-contrast: var(--ion-color-gold-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
    --ion-color-shade: var(--ion-color-gold-shade);
    --ion-color-tint: var(--ion-color-gold-tint);
  }

  /** cyan **/
  --ion-color-cyan: #13c2c2;
  --ion-color-cyan-rgb: 19, 194, 194;
  --ion-color-cyan-contrast: #ffffff;
  --ion-color-cyan-contrast-rgb: 255, 255, 255;
  --ion-color-cyan-shade: #11abab;
  --ion-color-cyan-tint: #2bc8c8;

  .ion-color-cyan {
    --ion-color-base: var(--ion-color-cyan);
    --ion-color-base-rgb: var(--ion-color-cyan-rgb);
    --ion-color-contrast: var(--ion-color-cyan-contrast);
    --ion-color-contrast-rgb: var(--ion-color-cyan-contrast-rgb);
    --ion-color-shade: var(--ion-color-cyan-shade);
    --ion-color-tint: var(--ion-color-cyan-tint);
  }

  /** shift-am **/
  --ion-color-shift-am: #57afef;
  --ion-color-shift-am-rgb: 87, 175, 239;
  --ion-color-shift-am-contrast: #ffffff;
  --ion-color-shift-am-contrast-rgb: 255, 255, 255;
  --ion-color-shift-am-shade: #4d9ad2;
  --ion-color-shift-am-tint: #68b7f1;

  .ion-color-shift-am {
    --ion-color-base: var(--ion-color-shift-am);
    --ion-color-base-rgb: var(--ion-color-shift-am-rgb);
    --ion-color-contrast: var(--ion-color-shift-am-contrast);
    --ion-color-contrast-rgb: var(--ion-color-shift-am-contrast-rgb);
    --ion-color-shade: var(--ion-color-shift-am-shade);
    --ion-color-tint: var(--ion-color-shift-am-tint);
  }

  /** shift-pm **/
  --ion-color-shift-pm: #0c71ba;
  --ion-color-shift-pm-rgb: 12, 113, 186;
  --ion-color-shift-pm-contrast: #ffffff;
  --ion-color-shift-pm-contrast-rgb: 255, 255, 255;
  --ion-color-shift-pm-shade: #0b63a4;
  --ion-color-shift-pm-tint: #247fc1;

  .ion-color-shift-pm {
    --ion-color-base: var(--ion-color-shift-pm);
    --ion-color-base-rgb: var(--ion-color-shift-pm-rgb);
    --ion-color-contrast: var(--ion-color-shift-pm-contrast);
    --ion-color-contrast-rgb: var(--ion-color-shift-pm-contrast-rgb);
    --ion-color-shade: var(--ion-color-shift-pm-shade);
    --ion-color-tint: var(--ion-color-shift-pm-tint);
  }

  /** shift-noc **/
  --ion-color-shift-noc: #375f7d;
  --ion-color-shift-noc-rgb: 55, 95, 125;
  --ion-color-shift-noc-contrast: #ffffff;
  --ion-color-shift-noc-contrast-rgb: 255, 255, 255;
  --ion-color-shift-noc-shade: #30546e;
  --ion-color-shift-noc-tint: #4b6f8a;

  .ion-color-shift-noc {
    --ion-color-base: var(--ion-color-shift-noc);
    --ion-color-base-rgb: var(--ion-color-shift-noc-rgb);
    --ion-color-contrast: var(--ion-color-shift-noc-contrast);
    --ion-color-contrast-rgb: var(--ion-color-shift-noc-contrast-rgb);
    --ion-color-shade: var(--ion-color-shift-noc-shade);
    --ion-color-tint: var(--ion-color-shift-noc-tint);
  }

  /** shift-custom **/
  --ion-color-shift-custom: #2573ff;
  --ion-color-shift-custom-rgb: 37, 115, 255;
  --ion-color-shift-custom-contrast: #ffffff;
  --ion-color-shift-custom-contrast-rgb: 255, 255, 255;
  --ion-color-shift-custom-shade: #2165e0;
  --ion-color-shift-custom-tint: #3b81ff;

  .ion-color-shift-custom {
    --ion-color-base: var(--ion-color-shift-custom);
    --ion-color-base-rgb: var(--ion-color-shift-custom-rgb);
    --ion-color-contrast: var(--ion-color-shift-custom-contrast);
    --ion-color-contrast-rgb: var(--ion-color-shift-custom-contrast-rgb);
    --ion-color-shade: var(--ion-color-shift-custom-shade);
    --ion-color-tint: var(--ion-color-shift-custom-tint);
  }

  /** shift-priority **/
  --ion-color-shift-priority: #6a67ce;
  --ion-color-shift-priority-rgb: 106, 103, 206;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-shift-priority-shade: #6a67ce;
  --ion-color-shift-priority-tint: #6a67ce;

  .ion-color-shift-priority {
    --ion-color-base: var(--ion-color-shift-priority);
    --ion-color-base-rgb: var(--ion-color-shift-priority-rgb);
    --ion-color-contrast: var(--ion-color-shift-priority-contrast);
    --ion-color-contrast-rgb: var(--ion-color-shift-priority-contrast-rgb);
    --ion-color-shade: var(--ion-color-shift-priority-shade);
    --ion-color-tint: var(--ion-color-shift-priority-tint);
  }

  /** streak **/
  --ion-color-streak: #ff5722;
  --ion-color-streak-rgb: 106, 103, 206;
  --ion-color-streak-contrast: #ffffff;
  --ion-color-streak-contrast-rgb: 255, 255, 255;
  --ion-color-streak-shade: #6a67ce;
  --ion-color-streak-tint: #6a67ce;

  .ion-color-streak {
    --ion-color-base: var(--ion-color-streak);
    --ion-color-base-rgb: var(--ion-color-streak-rgb);
    --ion-color-contrast: var(--ion-color-streak-contrast);
    --ion-color-contrast-rgb: var(--ion-color-streak-contrast-rgb);
    --ion-color-shade: var(--ion-color-streak-shade);
    --ion-color-tint: var(--ion-color-streak-tint);
  }

  /*ion-header-chip*/
  --ion-color-chip: #314252;
  --ion-color-chip-rgb: 37, 115, 255;
  --ion-color-chip-contrast: #fff;
  --ion-color-chip-contrast-rgb: 255, 255, 255;
  --ion-color-chip-shade: #387bb4;
  --ion-color-chip-tint: #5398d1;

  .ion-color-chip {
    --ion-color-base: var(--ion-color-chip);
    --ion-color-base-rgb: var(--ion-color-chip-rgb);
    --ion-color-contrast: var(--ion-color-chip-contrast);
    --ion-color-contrast-rgb: var(--ion-color-chip-contrast-rgb);
    --ion-color-shade: var(--ion-color-chip-shade);
    --ion-color-tint: var(--ion-color-chip-tint);
  }
  /*ion-card-header*/
  --ion-color-card: #f2f8fc;
  --ion-color-card-rgb: 37, 115, 255;
  --ion-color-card-contrast: #fff;
  --ion-color-card-contrast-rgb: 255, 255, 255;
  --ion-color-card-shade: #d5dade;
  --ion-color-card-tint: #f3f9fc;

  .ion-color-card {
    --ion-color-base: var(--ion-color-card);
    --ion-color-base-rgb: var(--ion-color-card-rgb);
    --ion-color-contrast: var(--ion-color-card-contrast);
    --ion-color-contrast-rgb: var(--ion-color-card-contrast-rgb);
    --ion-color-shade: var(--ion-color-card-shade);
    --ion-color-tint: var(--ion-color-card-tint);
  }
  /*ion-text-color*/
  --ion-color-text: #707070;
  --ion-color-text-rgb: 37, 115, 255;
  --ion-color-text-contrast: #fff;
  --ion-color-text-contrast-rgb: 255, 255, 255;
  --ion-color-text-shade: #636363;
  --ion-color-text-tint: #7e7e7e;

  .ion-color-text {
    --ion-color-base: var(--ion-color-text);
    --ion-color-base-rgb: var(--ion-color-text-rgb);
    --ion-color-contrast: var(--ion-color-text-contrast);
    --ion-color-contrast-rgb: var(--ion-color-text-contrast-rgb);
    --ion-color-shade: var(--ion-color-text-shade);
    --ion-color-tint: var(--ion-color-text-tint);
  }
  /*ion-tite-color*/
  --ion-color-title: #292929;
  --ion-color-title-rgb: 37, 115, 255;
  --ion-color-title-contrast: #fff;
  --ion-color-title-contrast-rgb: 255, 255, 255;
  --ion-color-title-shade: #242424;
  --ion-color-title-tint: #3e3e3e;

  .ion-color-title {
    --ion-color-base: var(--ion-color-title);
    --ion-color-base-rgb: var(--ion-color-title-rgb);
    --ion-color-contrast: var(--ion-color-title-contrast);
    --ion-color-contrast-rgb: var(--ion-color-title-contrast-rgb);
    --ion-color-shade: var(--ion-color-title-shade);
    --ion-color-tint: var(--ion-color-title-tint);
  }
  /*ion-secondarybtn-color*/
  --ion-color-secondarybtn: #fdc578;
  --ion-color-secondarybtn-rgb: 253, 197, 120;
  --ion-color-secondarybtn-contrast: #79592d;
  --ion-color-secondarybtn-contrast-rgb: 121, 89, 45;
  --ion-color-secondarybtn-shade: #8f9da6;
  --ion-color-secondarybtn-tint: #3e3e3e;

  .ion-color-secondarybtn {
    --ion-color-base: var(--ion-color-secondarybtn);
    --ion-color-base-rgb: var(--ion-color-secondarybtn-rgb);
    --ion-color-contrast: var(--ion-color-secondarybtn-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondarybtn-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondarybtn-shade);
    --ion-color-tint: var(--ion-color-secondarybtn-tint);
  }
  /*ion-emptyState-color*/
  --ion-color-emptyState: #fff0db;
  --ion-color-emptyState-rgb: 253, 217, 167;
  --ion-color-emptyState-contrast: #866332;
  --ion-color-emptyState-contrast-rgb: 158, 112, 48;
  --ion-color-emptyState-shade: #fdc578;
  --ion-color-emptyState-tint: #fae9d4;

  .ion-color-emptyState {
    --ion-color-base: var(--ion-color-emptyState);
    --ion-color-base-rgb: var(--ion-color-emptyState-rgb);
    --ion-color-contrast: var(--ion-color-emptyState-contrast);
    --ion-color-contrast-rgb: var(--ion-color-emptyState-contrast-rgb);
    --ion-color-shade: var(--ion-color-emptyState-shade);
    --ion-color-tint: var(--ion-color-emptyState-tint);
  }
  /*ion-alertbox-color*/
  --ion-color-alert: #e6f8ff;
  --ion-color-alert-rgb: 207, 240, 253;
  --ion-color-ealert-contrast: #707070;
  --ion-color-alert-contrast-rgb: 1112, 112, 112;
  --ion-color-alert-shade: #ace0f4;
  --ion-color-alert-tint: #d4f2fd;

  .ion-color-alert {
    --ion-color-base: var(--ion-color-alert);
    --ion-color-base-rgb: var(--ion-color-alert-rgb);
    --ion-color-contrast: var(--ion-color-alert-contrast);
    --ion-color-contrast-rgb: var(--ion-color-alert-contrast-rgb);
    --ion-color-shade: var(--ion-color-alert-shade);
    --ion-color-tint: var(--ion-color-alert-tint);
  }

  .ion-opacity,
  .ion-opacity * {
    opacity: 1 !important;
  }

  ion-toolbar {
    --background: var(--ion-color-dark);
    --color: var(--ion-color-primary-contrast);
    --border-width: 0 !important;
  }

  ion-back-button {
    --color: var(--ion-color-light);
    /**
     * Increase padding around the back button to improve mobile usability and
     * accessibility. Generally, the goal is to have a 48x48 px target.
     * See https://clipboardhealth.atlassian.net/browse/CH-19579
     */
    padding: 8px 12px;
  }
  ion-chip {
    --background: var(--ion-color-chip);
    --color: var(--ion-color-chip-contrast);
  }
  ion-card-header {
    --background: var(--ion-color-card);
  }

  /* Set the font family of the entire app */
  --ion-font-family: "SF Pro", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto",
    sans-serif;

  /** tooltip-colors **/
  --ion-color-dark-black: #101010;
  --ion-color-grey-text: #6e6e6e;
  --ion-color-blue: #ace0f4;
  --ion-color-text-blue: #6b96e9;
  --ion-instant-book-modal-text: #616161;

  /** gray button colors **/
  --ion-color-gray: #e0e0e0;
  --ion-color-gray-rgb: 224, 224, 224;
  --ion-color-gray-contrast: #979797;
  --ion-color-gray-contrast-rgb: 151, 151, 151;
  --ion-color-gray-shade: #c5c5c5;
  --ion-color-gray-tint: #e3e3e3;
}
